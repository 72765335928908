<template>
  <v-card :loading="isLoading">
    <v-card-title>
      <h3 class="card-label">
        <div v-if="originalData.name">
          <small>Sözleşme Türü</small><br />
          <span>{{ originalData.name }}</span>
        </div>
        <span v-else>Sözleşme Türü</span>
      </h3>
    </v-card-title>
    <v-card-subtitle>
      <div class="d-block w-100 mb-2">
        <rs-return-to />
      </div>
    </v-card-subtitle>
    <v-card-text>
      <v-form ref="form" @submit.prevent="handleFormSubmit">
        <v-row dense>
          <v-col sm="12">
            <rs-text-field
              label="Sözleşme Türü"
              :rules="[rules.required, rules.maxLength(formData.name, 100)]"
              v-model="formData.name"
              :readonly="disabled || (id && !originalData.cluster_id)"
              :filled="disabled || (id && !originalData.cluster_id)"
            />
          </v-col>
          <v-col cols="12">
            <v-switch
              v-model="formData.is_active"
              :label="formData.is_active ? $t('active') : $t('passive')"
              hide-details="auto"
              :readonly="disabled"
              :filled="disabled"
            />
          </v-col>
        </v-row>

        <rs-form-buttons
          :is-loading="isLoading"
          @cancel="handleCancelClick"
          @submit="handleFormSubmit"
          v-if="!disabled"
        />
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import hasForm from "@/view/mixins/hasForm";

export default {
  mixins: [hasForm],
  props: {
    id: {
      type: Number,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    id() {
      this.load();
    },
    clusterId: {
      handler() {
        if (this.id) {
          return;
        }

        this.$nextTick(() => {
          if (this.clusterId) {
            this.formData.cluster_id = this.clusterId;
          } else {
            this.formData.cluster_id = null;
          }
        });
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(["clusterId", "cluster"]),
  },
  mounted() {
    this.$nextTick(() => {
      if (this.id) {
        this.load();
      }
    });
  },
  data() {
    return {
      isLoading: false,
      formData: {
        cluster_id: null,
        name: null,
        is_active: true,
      },
    };
  },
  methods: {
    ...mapActions(["updateClusterId"]),
    /**
     * Retrieves item data
     * @return {Promise} Resolves if data is retrieved
     */
    load() {
      this.isLoading = true;

      return this.$api
        .query(`contract-types/${this.id}`)
        .then((response) => {
          this.originalData = Object.assign({}, response.data.data);
          for (const index in this.formData) {
            if (response.data.data[index] !== undefined) {
              this.formData[index] = response.data.data[index];
            }
          }

          if (
            this.originalData.cluster_id &&
            this.clusterId !== this.originalData.cluster_id
          ) {
            this.updateClusterId(this.originalData.cluster_id);
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleFormSubmit(event) {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      // TODO: replace this dummy method
      this.isLoading = true;
      const formData = Object.assign(
        { cluster_id: this.cluster_id },
        this.formData
      );
      formData.name = this.formData.name;

      if (this.id) {
        this.$api
          .put(`contract-types/${this.id}`, formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            if (event && event.closeOnSave) {
              this.$router.back();
              return;
            }

            this.loadData(response);

            this.name = response.data.data.name;
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.$api
          .post(`clusters/${this.clusterId}/contract-types`, formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            if (event && event.closeOnSave) {
              this.$router.back();
              return;
            }

            this.$router.replace({
              name: "other-definitions.types.contract-types.edit",
              params: { id: response.data.data.id },
            });
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    handleCancelClick() {
      this.$router.back();
    },
  },
};
</script>
